<template>
  <v-skeleton-loader
    v-if="loading"
    class="mr-2 mt-6"
    type="list-item-three-line"
  ></v-skeleton-loader>
  <div v-else>
    <v-card class="elevation-0">
      <v-row>
        <v-col xs="12" md="6">
          <div style="font-size: 1.25rem" class="px-4 font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.settings") }}
          </div>
        </v-col>
        <v-col class="px-6" xs="12" md="6">
          <v-btn
            class="float-right"
            color="primary"
            :disabled="$authorize([$role.USER])"
            @click="openModal"
          >
            {{
              isSettingAvailable
                ? $vuetify.lang.t("$vuetify.gpt_profile.update_settings")
                : $vuetify.lang.t("$vuetify.gpt_profile.create_settings")
            }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.default_profile") }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ gptSetting.default_profile }}
          </v-list-item-content>
          <v-btn
            :disabled="$authorize([$role.USER]) || !gptSetting.default_profile"
            class="float-right"
            color="error"
            @click="deleteSetting('default_profile')"
          >
            {{ $vuetify.lang.t("$vuetify.tooltip.delete") }}
          </v-btn>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.bot_mode_strict") }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ gptSetting.bot_mode_strict }}
          </v-list-item-content>
          <v-btn
            :disabled="gptSetting.bot_mode_strict == null || $authorize([$role.USER])"
            class="float-right"
            color="red"
            :dark="gptSetting.bot_mode_strict != null"
            @click="deleteSetting('bot_mode_strict')"
          >
            {{ $vuetify.lang.t("$vuetify.tooltip.delete") }}
          </v-btn>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.default_vision_profile") }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ gptSetting.default_vision_profile }}
          </v-list-item-content>
          <v-btn
            :disabled="$authorize([$role.USER]) || !gptSetting.default_vision_profile"
            class="float-right"
            color="error"
            @click="deleteSetting('default_vision_profile')"
          >
            {{ $vuetify.lang.t("$vuetify.tooltip.delete") }}
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.max_feedback_rating") }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ gptSetting.max_feedback_rating }}
          </v-list-item-content>
          <v-btn
            :disabled="$authorize([$role.USER]) || !gptSetting.max_feedback_rating"
            class="float-right"
            color="error"
            @click="deleteSetting('max_feedback_rating')"
          >
            {{ $vuetify.lang.t("$vuetify.tooltip.delete") }}
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.summary_settings") }}
          </v-list-item-content>
          <v-btn
            :disabled="!gptSetting.summary_settings"
            class="float-right mx-4"
            color="primary"
            @click="summaryModal = true"
          >
            {{ $vuetify.lang.t("$vuetify.gpt_profile.view_details") }}
          </v-btn>
          <v-btn
            :disabled="$authorize([$role.USER]) || !gptSetting.summary_settings"
            class="float-right"
            color="error"
            @click="deleteSetting('summary_settings')"
          >
            {{ $vuetify.lang.t("$vuetify.tooltip.delete") }}
          </v-btn>
        </v-list-item>
      </v-list>
      <DialogModal
        v-model="deleteDialog"
        :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
        :message="
          $vuetify.lang.t('$vuetify.gpt_profile.confirm_alert_msg_gpt_setting')
        "
        :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
        :cancelButtonText="$vuetify.lang.t('$vuetify.common.cancel_btn')"
        @onConfirmation="deleteGptSetting()"
        @onCancel="deleteDialog = false"
      />
      <!-- Update Gpt Setting  -->
      <v-dialog persistent v-model="gptSettingModal" max-width="100%">
        <v-card
          class="d-flex flex-column"
          style="overflow-y: scroll; overflow-x: hidden"
        >
          <v-container>
            <v-row>
              <v-col xs="12" md="6">
                <v-row>
                  <v-card-title class="font-weight-bold">
                    {{
                      isSettingAvailable
                        ? $vuetify.lang.t(
                            "$vuetify.gpt_profile.update_settings",
                          )
                        : $vuetify.lang.t(
                            "$vuetify.gpt_profile.create_settings",
                          )
                    }}
                  </v-card-title>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-bold mb-5">
                  {{
                    $vuetify.lang.t("$vuetify.gpt_profile.available_gpt_models")
                  }}
                </h3>
                <p class="models-text mb-10" v-html="highlightedModels"></p>
                <v-divider></v-divider>
                <h3 class="font-weight-bold mb-10 mt-10">
                  {{
                    $vuetify.lang.t(
                      "$vuetify.gpt_profile.summary_settings_example",
                    )
                  }}
                </h3>
                <v-divider></v-divider>
                <div>
                  <p class="mt-5">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.gpt_profile.summary_settings_explanation",
                      )
                    }}
                  </p>
                  <p class="font-weight-bold">
                    {{
                      $vuetify.lang.t("$vuetify.gpt_profile.required_fields")
                    }}
                  </p>
                  <p>
                    <span class="font-weight-medium"
                      >{{ $vuetify.lang.t("$vuetify.gpt_profile.units")
                      }}<span class="red--text text--accent-4">*</span
                      >{{
                        $vuetify.lang.t("$vuetify.gpt_profile.List_str")
                      }}</span
                    >{{ $vuetify.lang.t("$vuetify.gpt_profile.units_text") }}
                  </p>
                  <p>
                    <span class="font-weight-medium"
                      >{{ $vuetify.lang.t("$vuetify.gpt_profile.interval")
                      }}<span class="red--text text--accent-4">*</span
                      >{{
                        $vuetify.lang.t("$vuetify.gpt_profile.List_str")
                      }}</span
                    >{{ $vuetify.lang.t("$vuetify.gpt_profile.interval_text")
                    }}<span class="model-highlight font-weight-bold">{{
                      $vuetify.lang.t("$vuetify.gpt_profile.daily")
                    }}</span>
                  </p>
                  <p>
                    <span class="font-weight-medium"
                      >{{ $vuetify.lang.t("$vuetify.gpt_profile.prompt")
                      }}<span class="red--text text--accent-4">*</span>: </span
                    >{{ $vuetify.lang.t("$vuetify.gpt_profile.prompt_text") }}
                  </p>
                  <p>
                    <span class="font-weight-medium"
                      >{{ $vuetify.lang.t("$vuetify.gpt_profile.model")
                      }}<span class="red--text text--accent-4">*</span>: </span
                    >{{ $vuetify.lang.t("$vuetify.gpt_profile.model_text") }}
                  </p>
                  <p class="font-weight-bold">
                    {{ $vuetify.lang.t("$vuetify.gpt_profile.optional") }}
                  </p>
                  <p>
                    <span class="font-weight-medium"
                      >{{
                        $vuetify.lang.t("$vuetify.gpt_profile.exclude_filters")
                      }} </span
                    >{{
                      $vuetify.lang.t(
                        "$vuetify.gpt_profile.exclude_filters_text",
                      )
                    }}
                  </p>
                </div>
                <div class="mt-10">
                  <pre><code class="json-text">{{ defaultText }}</code></pre>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-skeleton-loader
              v-if="!gptSetting && !gptProfiles"
              class="mr-2 mt-6"
              type="list-item-three-line"
            ></v-skeleton-loader>

            <v-form class="mt-4" v-else ref="gpt_setting">
              <v-row>
                <v-col cols="12" md="12" xs="12">
                  <v-autocomplete
                    v-model="gptSetting.default_profile"
                    :items="gptProfiles"
                    label="Default Profile"
                    outlined
                    item-text="name"
                    item-id="id"
                    item-value="id"
                    :rules="defaultProfileRules"
                  >
                    <template
                      v-slot:append-item
                      v-if="gptProfiles.length < gpt_profiles_total_hits"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <div class="d-flex">
                            <v-btn
                              class="ma-0 pa-0"
                              @click="getGPTProfiles()"
                              color="primary"
                              text
                            >
                              {{ $vuetify.lang.t("$vuetify.common.load_more") }}
                            </v-btn>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-select
                    v-model="gptSetting.bot_mode_strict"
                    :items="[true, false]"
                    label="Bot Mode Strict"
                    outlined
                  >
                  </v-select>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="gptSetting.max_feedback_rating"
                    :rules="maxFeedbackRules"
                    label="Max Feedback Rating"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="gptSetting.default_vision_profile"
                    :items="visionProfiles"
                    :disabled="visionProfiles && visionProfiles.length < 1"
                    label="Default Vision Profile"
                    outlined
                    item-text="name"
                    item-id="id"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    v-model="summaryValue"
                    label="Summary Settings"
                    :rules="summarySettingRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>

            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="error"
                    outlined
                    text
                    @click="discardChanges"
                  >
                    {{$vuetify.lang.t('$vuetify.common.close_btn')}}
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    @click="
                      isSettingAvailable
                        ? updateGptSetting()
                        : createGptSetting()
                    "
                  >
                    {{
                      isSettingAvailable
                        ? $vuetify.lang.t(
                          "$vuetify.gpt_profile.update_settings",
                        )
                        : $vuetify.lang.t(
                          "$vuetify.gpt_profile.create_settings",
                        )
                    }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="summaryModal" max-width="100%">
        <v-card
          class="d-flex flex-column"
          style="overflow-y: scroll; overflow-x: hidden"
        >
          <v-container>
            <v-row>
              <v-col xs="12" md="6">
                <v-row>
                  <v-card-title class="font-weight-bold">
                    {{
                      $vuetify.lang.t("$vuetify.gpt_profile.summary_settings")
                    }}
                  </v-card-title>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong> Model:</strong>
                    <span class="response-details-text">
                      {{
                        gptSetting &&
                        gptSetting.summary_settings &&
                        gptSetting.summary_settings.model
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong> Prompt:</strong>
                    <span class="response-details-text">
                      {{
                        gptSetting &&
                        gptSetting.summary_settings &&
                        gptSetting.summary_settings.prompt
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong> Interval:</strong>
                    <span class="response-details-text">
                      {{
                        gptSetting &&
                        gptSetting.summary_settings &&
                        gptSetting.summary_settings.interval
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong> Exclude Filters:</strong>
                    <span class="response-details-text">
                      {{
                        gptSetting &&
                        gptSetting.summary_settings &&
                        gptSetting.summary_settings.exclude_filters
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong> Units:</strong>
                    <span class="response-details-text">
                      {{
                        gptSetting &&
                        gptSetting.summary_settings &&
                        gptSetting.summary_settings.units
                      }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="error"
                    outlined
                    text
                    @click="summaryModal = false"
                  >
                    {{ $vuetify.lang.t("$vuetify.common.close_btn") }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  ADD_ALERT,
  CREATE_GPT_SETTINGS,
  DELETE_GPT_SETTINGS,
  GET_GPT_PROFILES,
  GET_GPT_SETTINGS,
  UPDATE_GPT_SETTINGS,
} from "@/store/_actiontypes";
import DialogModal from "@/components/common/DialogModal";
import { GPT_MODELS } from "@/global/_constants";

export default {
  components: {
    DialogModal,
  },
  props: {
    tabName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      gptProfiles: [],
      gpt_profiles_total_hits: 0,
      currentPage: 0,
      isLoading: false,
      pageSize: 100,
      gptSetting: {},
      tempGptSetting: null,
      isSettingAvailable: false,
      deleteDialog: false,
      toBeDeletedSetting: null,
      gptSettingModal: false,
      summaryModal: false,
      summaryValue:
        this.gptSetting && this.gptSetting.summary_settings
          ? JSON.stringify(this.gptSetting.summary_settings)
          : "",
      defaultProfileRules: [
        value => {
          if (this.isSettingAvailable) {
            return true;
          } else {
            return value || "Select Default Profile";
          }
        },
      ],
      maxFeedbackRules: [
        value => {
          if (this.isSettingAvailable) {
            return value >= 0 || "Min value 0";
          } else {
            return true;
          }
        },
      ],
      summarySettingRules: [
        value => {
          if (value) {
            const validJson = this.isValidJSON(value);

            return validJson || "Enter Valid JSON";
          } else {
            return true;
          }
        },
      ],
      defaultText: {
        summary_settings: {
          units: ["user_id"],
          interval: ["daily"],
          prompt:
            "define specific rules and instructions for the summary building",
          model: "gpt-3.5-turbo-0613",
          exclude_filters: {
            gpt_profile_id: ["a0cc6beb-2909-459b-be55-62196af78ce4"],
            "conversation_metadata.project_id": ["project_id"],
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      selectedProject: state => state.project.selectedProject,
      // gptProfiles: (state) => state.project.gptProfiles.profiles,
      visionProfiles: state => {
        return state.project.gptProfiles.profiles?.filter(profile =>
          profile.model.includes("vision")
        );
      },
    }),
    highlightedModels() {
      return GPT_MODELS.map(model => {
        return `<span class="model-highlight">${model}</span>`;
      }).join(", ");
    },
  },

  watch: {
    gptSetting: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.summary_settings) {
          this.summaryValue = JSON.stringify(newVal.summary_settings);
        } else {
          this.summaryValue = "";
        }
      },
    },
    tabName: {
      immediate: true,
      handler(newVal) {
        if (newVal === "gptSetting") {
          this.getGptSetting();
          this.getGPTProfiles();
        }
      },
    },
  },
  methods: {
    ...mapActions("project", [
      GET_GPT_SETTINGS,
      DELETE_GPT_SETTINGS,
      GET_GPT_PROFILES,
      CREATE_GPT_SETTINGS,
      UPDATE_GPT_SETTINGS,
    ]),

    discardChanges() {
      this.gptSetting = JSON.parse(JSON.stringify(this.tempGptSetting));
      this.tempGptSetting = null;
      this.gptSettingModal = false;
    },

    openModal() {
      this.tempGptSetting = JSON.parse(JSON.stringify(this.gptSetting));
      this.gptSettingModal = true;
    },

    async getGptSetting() {
      this.loading = true;
      const gptSettingsRes = await this.GET_GPT_SETTINGS({
        project_id: this.selectedProject.id,
      });
      this.loading = false;
      if (gptSettingsRes?.data?.detail?.settings) {
        this.gptSetting = gptSettingsRes?.data?.detail?.settings;
        this.isSettingAvailable = true;
      } else {
        this.isSettingAvailable = false;
      }
    },
    deleteSetting(item) {
      (this.deleteDialog = true), (this.toBeDeletedSetting = item);
    },

    deleteGptSetting() {
      this.deleteDialog = false;
      this.DELETE_GPT_SETTINGS({
        project_id: this.selectedProject.id,
        fields: [this.toBeDeletedSetting],
      })
        .then(_res => {
          this.getGptSetting();
        })
        .catch(e => {
          console.error("error", e);
        });
    },
    updateGptSetting() {
      const validForm = this.$refs.gpt_setting.validate();
      if (validForm) {
        this.UPDATE_GPT_SETTINGS({
          project_id: this.$route.params.project_id,
          gptSetting: {
            ...this.gptSetting,
            summary_settings: this.summaryValue
              ? JSON.parse(this.summaryValue)
              : null,
          },
        })
          .then(_res => {
            this.getGptSetting();
            this.gptSettingModal = false;
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: "Updated GPT setting successfully",
                color: "success",
              },
              { root: true },
            );
          })
          .catch(err => {
            console.error("error updating", err.response);
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: `Error occurred updating gpt settings, provide proper value`,
                color: "error",
              },
              { root: true },
            );
          });
      }
    },
    createGptSetting() {
      const validForm = this.$refs.gpt_setting.validate();
      if (validForm) {
        this.CREATE_GPT_SETTINGS({
          project_id: this.$route.params.project_id,
          default_profile: this.gptSetting.default_profile || null,
          default_vision_profile:
           
            this.gptSetting.default_vision_profile || null,
          bot_mode_strict: false,
          max_feedback_rating: this.gptSetting.max_feedback_rating || null,
          summary_settings: this.summaryValue
            ? JSON.parse(this.summaryValue)
            : null,
        })
          .then(_res => {
            this.getGptSetting();
            this.gptSettingModal = false;
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: "Created GPT setting successfully",
                color: "success",
              },
              { root: true },
            );
          })
          .catch(err => {
            console.error("error updating", err.response);
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: `Error occurred creating gpt settings, provide proper value`,
                color: "error",
              },
              { root: true },
            );
          });
      }
    },
    async getGPTProfiles() {
      try {
        if (this.isLoading) return;
        this.isLoading = true;
        const apiRes = await this.GET_GPT_PROFILES({
          project_id: this.$route.params.project_id,
          start: this.currentPage * this.pageSize,
          size: this.pageSize,
        });
        const newProfiles = apiRes?.data?.detail?.response.profiles || [];
        this.gptProfiles = [...this.gptProfiles, ...newProfiles];
        this.gpt_profiles_total_hits =
          apiRes?.data?.detail?.response?.total_hits;
        this.currentPage += 1;
        this.isLoading = false;
      } catch (e) {
        console.error("Error loading GPT profiles:", e);
        this.isLoading = false;
      }
    },
    isValidJSON(jsonString) {
      try {
        JSON.parse(jsonString);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
  mounted() {
    this.getGptSetting();
    this.getGPTProfiles();
  },
};
</script>

<style>
.models-text {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.model-highlight {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}

.json-text {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  font-family: monospace;
}
</style>
