import { render, staticRenderFns } from "./RepeatedUsers.vue?vue&type=template&id=e56c4a36&scoped=true"
import script from "./RepeatedUsers.vue?vue&type=script&lang=js"
export * from "./RepeatedUsers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e56c4a36",
  null
  
)

export default component.exports