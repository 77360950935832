import axios from "axios";
import store from "@/store";

import { ADD_ALERT, REFRESHTOKEN, TOGGLE_LOADING } from "@/store/_actiontypes";
import { CLEAR_ERROR } from "@/store/_mutationtypes";

import router from "@/router/index";
import getEnv from "@/config/env";
import { getCurrentUser, deleteCurrentUser } from "@/helpers/helper";

let isRefreshingToken = false;
let callbacks = [];
let api = axios.create({
  baseURL: getEnv("VUE_APP_BACKEND_API"),
});

api.interceptors.request.use(request => {
  // add authorization header with jwt token to each request
  let user = getCurrentUser();
  let wallet_user = JSON.parse(localStorage.getItem("wallet_user"));

  request.headers["X-Client-Id"] = getEnv("VUE_APP_CLIENT_ID");
  request.headers["X-Client-Secret"] = getEnv("VUE_APP_CLIENT_SECRET");

  if (user && request.url === "oauth2/token?grant_type=refresh_token") {
    request.headers["Authorization"] = `Bearer ${user.refresh_token}`;
  } else if (user) {
    request.headers["Authorization"] = `Bearer ${user.access_token}`;
    if (wallet_user && request.url.split("/").includes("wallet")) {
      request.headers["X-Wallet-Token"] = `${wallet_user.access_token}`;
    }
  }

  if (localStorage.getItem("language")) {
    request.headers["Accept-Language"] = localStorage.getItem("language");
  } else {
    request.headers["Accept-Language"] = "en-gb";
  }

  updateLoaderTo(true);
  return request;
});

api.interceptors.response.use(
  response => {
    updateLoaderTo(false);
    store.commit(`account/${CLEAR_ERROR}`);
    return response;
  },
  error => {
    updateLoaderTo(false);
    //checking if the call from oauth2/revoke
    const isLogoutRequest = error.config.url === "oauth2/revoke";

    //if yes & token expired, then back to LOGOUT
    if (isLogoutRequest && error.response?.status === 401) {
      return Promise.resolve(error);
    }

    var errormessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response && error.response.status === 422) {
      if (error.response.data.errors) {
        errormessage = "";
        error.response.data.errors.forEach(value => {
          errormessage += value.toString() + " ";
        });
      }
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url === "/wallet/access"
    ) {
      //pass
    } else if (error.response && error.response.status === 401) {
      let user = getCurrentUser();
      if (user && user.refresh_token) {
        const originalRequest = error.config;
        if (!isRefreshingToken) {
          isRefreshingToken = true;
          store
            .dispatch(
              `account/${REFRESHTOKEN}`,
              { refresh_token: user.refresh_token },
              { root: true },
            )
            .then(() => {
              isRefreshingToken = false;
              tokenRefreshed();
            })
            .catch(() => {
              // router.push("/login");
            });
        } else {
          deleteCurrentUser();
        }

        return new Promise(resolve => {
          addCallback(() => {
            originalRequest.headers.Authorization = `Bearer ${store.state.account.user.access_token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    errormessage =
      errormessage === "Request aborted"
        ? ""
        : errormessage === "The token has expired."
          ? ""
          : errormessage === "The token has been revoked."
            ? ""
            : errormessage ===
                "Destination Account either does not exist or is not activated yet."
              ? ""
              : errormessage ===
                  "宛先アカウントが存在しないか、まだアクティブ化されていません。"
                ? ""
                : errormessage;
    if (errormessage) {
      store.dispatch(
        `alert/${ADD_ALERT}`,
        { message: errormessage, color: "error" },
        { root: true },
      );
    }

    return Promise.reject(error);
  },
);

let updateLoaderTo = loading => {
  store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },

    { root: true },
  );
};

let tokenRefreshed = () => {
  callbacks = callbacks.filter(callback => callback());
};

let addCallback = callback => {
  // Fixme: callback creating problems on wrong passcode enter
  // callbacks.push(callback);
};

export default api;
