import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import getEnv from "@/config/env";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueCardFormat from "vue-credit-card-validation";
import sentry from "./plugins/sentry";
import './assets/css/custom.css';
import VueGtag from "vue-gtag";
import TokenRefreshPlugin from "@/plugins/tokenRefreshPlugin";
import {CAPTCHA_SITE_KEY} from "@/global/_constants";
import {reCaptchaPlugin} from "@/plugins/recaptchaPlugin";
import ipCheckPlugin from "@/plugins/ipCheckPlugin";
import tokenizerPlugin from "@/plugins/tokenizerPlugin";
import { Role } from "@/helpers/role";
import { authorize } from "@/helpers/helper";


// Make Role and authorize available globally
Vue.prototype.$role = Role;
Vue.prototype.$authorize = authorize;

Vue.config.productionTip = false;

Vue.use(ipCheckPlugin);
Vue.use(reCaptchaPlugin, {siteKey: CAPTCHA_SITE_KEY});
Vue.use(VueTelInputVuetify, {vuetify});
Vue.use(VueCardFormat);
Vue.use(VueGtag, {
  config: { id: "G-1D4B3FSF7Z" },
  enabled: getEnv("NODE_ENV") === 'production',
});
Vue.use(TokenRefreshPlugin);
Vue.use(tokenizerPlugin);

new Vue({
  router,
  store,
  vuetify,
  sentry,
  render: (h) => h(App),
}).$mount("#app");
