<template>
  <v-expansion-panels>
    <v-expansion-panel v-model="panel">
      <v-expansion-panel-header>
        <div class="text-h6 font-weight-bold ml-n2">
          {{ `${$vuetify.lang.t("$vuetify.project_settings.update_mapper")}` }}
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form ref="update_form">
          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>item_id*</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="itemId" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>parent_item_id</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="parentItemId" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>title*</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="title" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>second_title</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="secondTitle" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>third_title</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="thirdTitle" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>fourth_title</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="fourthTitle" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>availability*</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="availability" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>description</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="description" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>image_url</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="imageUrl" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>image_url_type</span>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="imageUrlType"
                :items="['STR', 'LIST_STR', 'LIST_DICT', 'DICT']"
                outlined
                dense
                hide-selected
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>item_url*</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="itemUrl" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>price*</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="price" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>categories*</span>
            </v-col>
            <v-col cols="6">
              <div
                v-for="(category, index) in categories"
                :key="index"
                class="side-by-side"
              >
                <v-text-field
                  v-model="category.name"
                  outlined
                  dense
                ></v-text-field>

                <!-- Separator input box -->
                <v-text-field
                  v-model="category.separator"
                  outlined
                  dense
                ></v-text-field>

                <!-- Button to remove this category -->
                <v-btn
                  v-if="categories.length > 1"
                  @click="removeSubfield('categories', index)"
                  outlined> Remove </v-btn>
              </div>
              <!-- Button to add more categories -->
              <v-btn
                @click="addSubfield('categories')"
                outlined
              > Add More </v-btn>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>flag*</span>
            </v-col>
            <v-col cols="6">
              <div v-for="(elem, index) in flag" :key="index" class="side-by-side">
                <v-text-field v-model="flag[index]" outlined dense></v-text-field>

                <!-- Button to remove this category -->
                <v-btn
                  v-if="flag.length > 1"
                  @click="removeSubfield('flag', index)"
                  outlined
                > Remove</v-btn>
              </div>
              <!-- Button to add more categories -->
              <v-btn
                @click="addSubfield('flag')"
                outlined
              > Add More </v-btn>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>average_rating</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="averageRating" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>user_ratings_total</span>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userRatingsTotal" outlined dense></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>custom</span>
            </v-col>
            <v-col cols="6">
              <div
                v-for="(elem, index) in custom"
                :key="index"
                class="side-by-side"
              >
                <v-text-field
                  v-model="elem.name"
                  outlined
                  dense
                ></v-text-field>

                <!-- Data type input box -->
                <v-autocomplete
                  v-model="elem.data_type"
                  :items="['INT', 'FLOAT']"
                  outlined
                  dense
                  hide-selected
                ></v-autocomplete>

                <!-- Button to remove this category -->
                <v-btn
                  v-if="custom.length > 0"
                  @click="removeSubfield('custom', index)"
                  outlined
                > Remove </v-btn>
              </div>
              <!-- Button to add more categories -->
              <v-btn @click="addSubfield('custom')" outlined>
                {{ custom.length === 0 ? "Add Field" : "Add More" }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>item_nearby_calculation</span>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="itemNearbyCalculation"
                :items="[true, false]"
                outlined
                dense
                hide-selected
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>keywords_group_by</span>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="keywordsGroupBy"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>gpt_enabled</span>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="gptEnabled"
                :items="[true, false]"
                outlined
                dense
                hide-selected
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row cols="12" md="6">
            <v-col cols="2">
              <span>search_settings</span>
            </v-col>
            <v-col cols="6">
              <div>
                <!--TODO: Need to render CSV Header as :items & component > autocomplete  -->
                <v-text-field
                  v-model="updateSearchSettings.prioritize_key"
                  dense
                  label="Prioritize Key"
                  hide-selected
                  outlined
                ></v-text-field>
                <v-autocomplete
                  v-model="updateSearchSettings.prioritize_category"
                  :items="categories.map(category => category.name)"
                  label="Prioritize Category"
                  dense
                  small-chips
                  multiple
                  outlined
                  hide-selected
                  deletable-chips
                  :rules="[
                    (v) => validatePrioritizeCategory() ||
                      $vuetify.lang.t('$vuetify.project.field_required_for_prioritize_category')
                  ]"
                ></v-autocomplete>
                <div>
                  <div
                    v-if="updateSearchSettings.prioritize_values.length > 0"
                    class="d-flex flex-wrap mb-2"
                  >
                    <v-chip
                      v-for="(item, index) in updateSearchSettings.prioritize_values"
                      :key="index"
                      close
                      @click:close="handleRemovePrioritizeValue(index)"
                      small
                      class="ma-1"
                    >
                      {{ item }}
                    </v-chip>
                  </div>
                  <div>
                    <v-text-field
                      class="mb-0 pb-0"
                      v-model="inputUpdatePrioritizeValue"
                      label="Prioritize Values"
                      dense
                      outlined
                      small-chips
                      @keyup.enter="handleAddUpdatePrioritizeValue"
                      clearable
                      :rules="[
                        (v) => validatePrioritizeValues() ||
                          $vuetify.lang.t('$vuetify.project.field_required_for_prioritize_values')
                      ]"
                    ></v-text-field>

                    <v-btn
                      class="mb-8"
                      @click="handleAddUpdatePrioritizeValue"
                      outlined
                    > Add Value </v-btn>
                  </div>
                </div>
                <!-- v-model="selectUpdatePrioritizeFlag" -->
                <div class="side-by-side">
                  <v-autocomplete
                    v-model="selectUpdatePrioritizeFlag"
                    :items="flag.map(flag => flag)"
                    label="Prioritize Flag"
                    dense
                    hide-selected
                    outlined
                    @change="handleUpdateInputPrioritizeFlag"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="setUpdatePrioritizeFlagValue"
                    :items="['true', 'false']"
                    label="Flag Value"
                    dense
                    hide-selected
                    outlined
                    @change="handleUpdateInputPrioritizeFlag"
                  ></v-autocomplete>
                </div>

                <div>
                  <div
                    v-if="updateSearchSettings.downgrade_values.length > 0"
                    class="d-flex flex-wrap mb-2"
                  >
                    <v-chip
                      v-for="(item, index) in updateSearchSettings.downgrade_values"
                      :key="index"
                      close
                      @click:close="handleRemoveDowngradeValue(index)"
                      small
                      class="ma-1"
                    >
                      {{ item }}
                    </v-chip>
                  </div>

                  <div>
                    <v-text-field
                      class="mb-0 pb-0"
                      v-model="inputUpdateDowngradeValue"
                      label="Downgrade Values"
                      dense
                      outlined
                      small-chips
                      @keyup.enter="handleAddUpdateDowngradeValue"
                      clearable
                    ></v-text-field>

                    <v-btn
                      class="mb-8"
                      @click="handleAddUpdateDowngradeValue"
                      outlined
                    > Add Value </v-btn>
                  </div>
                </div>
                <div class="side-by-side">
                  <v-text-field
                    v-model="updateSearchSettings.cluster_size"
                    label="Cluster Size"
                    type="number"
                    dense
                    outlined
                    :min="1"
                    :max="5000"
                    :rules="[validateMinMax(1, 5000)]"
                  ></v-text-field>
                  <v-select
                    v-model="updateSearchSettings.is_keyword_enabled"
                    :items="[true, false]"
                    label="Is Keyword Enabled"
                    dense
                    hide-selected
                    outlined
                  ></v-select>
                  <v-text-field
                    v-model="updateSearchSettings.top_k"
                    label="Top K"
                    type="number"
                    dense
                    outlined
                    :min="1"
                    :max="50"
                    :rules="[validateMinMax(1, 50)]"
                  ></v-text-field>
                </div>
                <div class="side-by-side">
                  <v-text-field
                    v-model="updateSearchSettings.keyword_ngram"
                    label="Keyword Ngram"
                    type="number"
                    dense
                    outlined
                    :min="1"
                    :max="3"
                    :rules="[validateMinMax(1, 3)]">
                  </v-text-field>
                  <v-text-field
                    v-model="updateSearchSettings.depth"
                    label="Depth"
                    type="number"
                    dense
                    outlined
                    :min="1"
                    :max="3"
                    :rules="[validateMinMax(1, 3)]"
                  ></v-text-field>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row cols="12">
            <v-col>
              <v-btn
                @click="updateMapper"
                color="primary"
                :loading="isLoading"
                :disabled="$authorize([$role.USER])"
              >
                {{
                  `${$vuetify.lang.t("$vuetify.common.update_btn")}`
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions } from "vuex";

import {
  ADD_ALERT,
  GET_MAPPER,
  CREATE_MAPPER,
  UPDATE_MAPPER,
  CREATE_INDICES,
  CREATE_RANK,
  UPLOAD_RECOMMENDER_DATA,
  ITEMS_TRAIN,
  IMAGES_TRAIN,
  RANKS_TRAIN,
} from "@/store/_actiontypes";

export default {
  props: {
    mapper: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      isLoading: false,
      itemId: "",
      parentItemId: "",
      title: "",
      secondTitle: "",
      thirdTitle: "",
      fourthTitle: "",
      availability: "",
      description: "",
      imageUrl: "",
      imageUrlType: "",
      itemUrl: "",
      price: "",
      categories: [],
      flag: [],
      averageRating: "",
      userRatingsTotal: "",
      custom: [],
      itemNearbyCalculation: "",
      keywordsGroupBy: "",
      gptEnabled: "",
      panel: false,
      inputUpdatePrioritizeValue: "",
      inputUpdateDowngradeValue: "",
      selectUpdatePrioritizeFlag: "",
      setUpdatePrioritizeFlagValue: "",
      updateSearchSettings: {
        prioritize_key:"",
        prioritize_category: [],
        prioritize_values: [],
        prioritize_flags: {},
        downgrade_values:[],
        cluster_size: "",
        is_keyword_enabled: "",
        top_k: "",
        keyword_ngram:"",
        depth: ""
      },
    };
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
    }),
  },
  watch: {
    mapper: {
      handler(newVal) {
        if (newVal.detail) {
          this.updateLocalData(newVal);
        }
      },
      immediate: true,
    },
    updateSearchSettings: {
      handler: function () {
        this.$refs.update_form && this.$refs.update_form.validate();
      },
      deep: true,
    },
  },
  mounted() { },
  beforeCreate() { },
  created() { },
  beforeDestroy() { },
  methods: {
    ...mapActions("project", [
      GET_MAPPER,
      CREATE_MAPPER,
      UPDATE_MAPPER,
      CREATE_INDICES,
      CREATE_RANK,
      UPLOAD_RECOMMENDER_DATA,
      ITEMS_TRAIN,
      IMAGES_TRAIN,
      RANKS_TRAIN,
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    updateMapper() {
      this.isLoading = true;
      if (!this.$refs.update_form.validate()) {
        this.isLoading = false;
        return
      }
      let newMapper = {
        key_map: {
          item_id: this.itemId,
          parent_item_id: this.parentItemId,
          title: this.title,
          second_title: this.secondTitle,
          third_title: this.thirdTitle,
          fourth_title: this.fourthTitle,
          availability: this.availability,
          description: this.description,
          image_url: this.imageUrl,
          image_url_type: this.imageUrlType,
          item_url: this.itemUrl,
          price: this.price,
          categories: this.categories,
          flag: this.flag,
          average_rating: this.averageRating,
          user_ratings_total: this.userRatingsTotal,
          custom: this.custom,
          item_nearby_calculation: this.itemNearbyCalculation,
          keywords_group_by: this.keywordsGroupBy,
          gpt_enabled: this.gptEnabled,
          search_settings: {
            prioritize_key: this.updateSearchSettings.prioritize_key,
            prioritize_category: this.updateSearchSettings.prioritize_category,
            prioritize_values: this.updateSearchSettings.prioritize_values,
            prioritize_flags: this.updateSearchSettings.prioritize_flags,
            downgrade_values: this.updateSearchSettings.downgrade_values,
            cluster_size: parseInt(this.updateSearchSettings.cluster_size),
            is_keyword_enabled: this.updateSearchSettings.is_keyword_enabled,
            top_k: parseInt(this.updateSearchSettings.top_k),
            keyword_ngram:parseInt(this.updateSearchSettings.keyword_ngram),
            depth: parseInt(this.updateSearchSettings.depth),
          },
        },
      };
      newMapper = this.cleanMapperObject(newMapper);

      this.UPDATE_MAPPER({
        project_id: this.selectedProject.id,
        mapper_body: newMapper,
      }).then(
        (response) => {
          this.$emit("updateComplete");
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: response.data?.detail?.response,
              color: "success",
            },
            { root: true }
          );
          this.isLoading = false;
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: error.response.data.detail[0].msg,
                color: "error",
              },
              { root: true }
            );
          }
          this.isLoading = false;
        }
      );
    },
    addSubfield(field) {
      if (field === "categories") {
        this.categories.push({
          name: "",
          separator: "",
        });
      } else if (field === "flag") {
        this.flag.push("");
      } else if (field === "custom") {
        this.custom.push({
          name: "",
          data_type: "INT",
        });
      }
    },
    removeSubfield(field, index) {
      if (field === "categories") {
        this.categories.splice(index, 1);
      } else if (field === "flag") {
        this.flag.splice(index, 1);
      } else if (field === "custom") {
        this.custom.splice(index, 1);
      }
    },
    cleanMapperObject(mapper) {
      const cleanedMapper = { ...mapper };

      // Remove keys with null values
      Object.keys(cleanedMapper.key_map).forEach((key) => {
        if (cleanedMapper.key_map[key] === null || cleanedMapper.key_map[key] === "") {
          delete cleanedMapper.key_map[key];
        }
      });

      // Remove empty objects for "custom" key and handle "name" checks
      if (cleanedMapper.key_map.custom) {
        cleanedMapper.key_map.custom = cleanedMapper.key_map.custom.filter((elem) => {
          return elem.name !== "";
        });

        // Omit "custom" key if any element has a name of null
        if (cleanedMapper.key_map.custom.length === 0) {
          delete cleanedMapper.key_map.custom;
        }
      }

      // Remove "flag" key if all elements are empty strings
      if (cleanedMapper.key_map.flag && cleanedMapper.key_map.flag.every((flagElem) => flagElem === "")) {
        delete cleanedMapper.key_map.flag;
      }

      return cleanedMapper;
    },
    updateLocalData(newVal) {
      this.itemId = newVal.detail.response.item_id;
      this.parentItemId = newVal.detail.response.parent_item_id;
      this.title = newVal.detail.response.title;
      this.secondTitle = newVal.detail.response.second_title;
      this.thirdTitle = newVal.detail.response.third_title;
      this.fourthTitle = newVal.detail.response.fourth_title;
      this.availability = newVal.detail.response.availability;
      this.description = newVal.detail.response.description;
      this.imageUrl = newVal.detail.response.image_url;
      this.imageUrlType = newVal.detail.response.image_url_type;
      this.itemUrl = newVal.detail.response.item_url;
      this.price = newVal.detail.response.price;
      this.categories = newVal.detail.response.categories ? [...newVal.detail.response.categories] : [];
      this.flag = newVal.detail.response.flag ? [...newVal.detail.response.flag] : [];
      this.averageRating = newVal.detail.response.average_rating;
      this.userRatingsTotal = newVal.detail.response.user_ratings_total;
      this.custom = newVal.detail.response.custom ? [...newVal.detail.response.custom] : [];
      this.itemNearbyCalculation = newVal.detail.response.item_nearby_calculation;
      this.keywordsGroupBy = newVal.detail.response.keywords_group_by;
      this.gptEnabled = newVal.detail.response.gpt_enabled;
      this.selectUpdatePrioritizeFlag = Object.keys(newVal.detail.response.search_settings.prioritize_flags)[0];
      this.setUpdatePrioritizeFlagValue = Object.values(newVal.detail.response.search_settings.prioritize_flags)[0];
      this.updateSearchSettings = {
        prioritize_key: newVal.detail.response.search_settings.prioritize_key,
        prioritize_category: newVal.detail.response.search_settings.prioritize_category,
        prioritize_values: newVal.detail.response.search_settings.prioritize_values ? [...newVal.detail.response.search_settings.prioritize_values] : [],
        prioritize_flags: newVal.detail.response.search_settings.prioritize_flags ? {...newVal.detail.response.search_settings.prioritize_flags} : {},
        downgrade_values: newVal.detail.response.search_settings.downgrade_values ? [...newVal.detail.response.search_settings.downgrade_values] : [],
        cluster_size: newVal.detail.response.search_settings.cluster_size,
        is_keyword_enabled: newVal.detail.response.search_settings.is_keyword_enabled,
        top_k: newVal.detail.response.search_settings.top_k,
        keyword_ngram:newVal.detail.response.search_settings.keyword_ngram,
        depth:newVal.detail.response.search_settings.depth
      };
    },
    handleUpdateInputPrioritizeFlag() {
      this.$set(this.updateSearchSettings, 'prioritize_flags', {}); // Clear the existing properties in prioritize_flags
      if (
        this.selectUpdatePrioritizeFlag &&
        this.setUpdatePrioritizeFlagValue !== ""
      ) {
        this.$set(
          this.updateSearchSettings.prioritize_flags,
          this.selectUpdatePrioritizeFlag,
          this.setUpdatePrioritizeFlagValue
        );
      }
    },
    handleAddUpdatePrioritizeValue() {
      if (this.inputUpdatePrioritizeValue.trim() !== '') {
        this.updateSearchSettings.prioritize_values.push(this.inputUpdatePrioritizeValue);
        this.inputUpdatePrioritizeValue = '';
      }
    },
    handleAddUpdateDowngradeValue() {
      if (this.inputUpdateDowngradeValue.trim() !== '') {
        this.updateSearchSettings.downgrade_values.push(this.inputUpdateDowngradeValue);
        this.inputUpdateDowngradeValue = '';
      }
    },
    handleRemovePrioritizeValue(index) {
      this.updateSearchSettings.prioritize_values.splice(index, 1);
    },
    handleRemoveDowngradeValue(index) {
      this.updateSearchSettings.downgrade_values.splice(index, 1);
    },
    validateMinMax(min, max) {
      return value => {
        if (value === '' || value === null) {
          return true;
        }
        if (value < min || value > max) {
          return `Value must be between ${min} and ${max}`;
        }
        return true;
      };
    },
    validatePrioritizeCategory() {
      if (
        this.updateSearchSettings.prioritize_values.length > 0 &&
        !(this.updateSearchSettings.prioritize_category.length > 0)
      ) {
        return false;
      }
      return true;
    },
    validatePrioritizeValues() {
      if (
        this.updateSearchSettings.prioritize_category.length > 0 &&
        this.updateSearchSettings.prioritize_values.length === 0
      ) {
        return false;
      }
      return true;
    },
    // validatePrioritizeCategory() {
    //     // console.log("updated mapped data for category: ",mappedData)

    //     if ((this.updateSearchSettings.prioritize_values.length > 0 && !this.updateSearchSettings.prioritize_category) || this.updateSearchSettings.prioritize_values.length !== 0 ) {
    //         return false;
    //     }
    //     return true;
    // },
    // validatePrioritizeValues() {
    //     // console.log("updated mapped data for values: ",mappedData)
    //     if (this.updateSearchSettings.prioritize_category && this.updateSearchSettings.prioritize_values.length === 0) {
    //         return false;
    //     }
    //     return true;
    // },
  },
};
</script>

<style scoped>
.secondary-disabled-button {
  cursor: not-allowed;
  pointer-events: none;
}

.side-by-side {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
}
</style>
