<template>
  <v-card v-if="showAccountInfo" class="elevation-0">
    <v-card-title class="font-weight-bold">
      {{ $vuetify.lang.t("$vuetify.account.acc_info_header_txt") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.account.account_type_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ accountType ? capitalizeFirstLetter(accountType) : "N/A" }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.account.account_name_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ accountName ? accountName : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <div v-if="accountType === 'organization'">
        <v-list-item v-if="isVisible">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_name_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.name ? organization.name : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isVisible">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.vat_no_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.vat_number ? organization.vat_number : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isVisible">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.reg_no_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              organization.registration_number
                ? organization.registration_number
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isVisible">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.ceo_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              organization.director_name ? organization.director_name : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_email_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.email ? organization.email : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isVisible">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_phone_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.phone ? organization.phone : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.website">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_website_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.website }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.size">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_size_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              oraganizationSizeParams.filter(
                obj => obj.value === organization.size,
              )[0].text
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.industry">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.account.org_industry_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.industry.name }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.country">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.profile.country_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.country.countryName }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.region">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.profile.state_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.region.name }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.city">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.profile.city_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.city }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.postal_code">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.profile.post_code_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.postal_code }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="organization.address">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.profile.address_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ organization.address }}
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import { capitalizeFirstLetter } from "@/helpers/helper";

export default {
  props: {
    showAccountInfo: {
      type: Boolean,
      default: false,
    },
    accountType: {
      type: String,
      default: () => {},
    },
    accountName: {
      type: String,
      default: () => {},
    },
    organization: {
      type: Object,
      default: () => {},
    },
    oraganizationSizeParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_1_txt"),
            value: "0 - 9",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_2_txt"),
            value: "10 - 49",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_3_txt"),
            value: "50 - 149",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_4_txt"),
            value: "150 - 249",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_5_txt"),
            value: "250 - 999",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_6_txt"),
            value: "1000 ~ ",
          },
        ];
      },
    },
  },
  data() {
    return {
      capitalizeFirstLetter,
      isVisible: false,
    };
  },
};
</script>
