export class GptConversationMapper {
  static mapBackendToFrontend(conversation) {
    // Function to map the inner object
    const mapObject = obj => {
      const mapped = {};

      if (!obj) {
        return {};
      }

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          mapped[key] = obj[key];
        }
  
        
      }
      return mapped;
    };

    const mapContextPreference = contextPreference => {
      const mappedContextPreference = {};

      if (!contextPreference) {
        return {};
      }

      for (const key in contextPreference) {
        if (Object.prototype.hasOwnProperty.call(contextPreference, key)) {
          mappedContextPreference[key] = mapObject(contextPreference[key]);
        }
      }

      return mappedContextPreference;
    };

    return {
      id: conversation.id,
      processTime: conversation.process_time,
      requestBody: {
        userId: conversation.request_body.user_id,
        memberId: conversation.request_body.member_id,
        query: conversation.request_body.query,
        conversationType: conversation.request_body?.conversation_type,
        gptProfileId: conversation.request_body?.gpt_profile_id,
        contextPreference: mapContextPreference(
          conversation.request_body?.context_preference,
        ),
        conversationMetadata: mapObject(
          conversation.request_body?.conversation_metadata,
        ),
        sessionId: conversation.request_body?.session_id,
        resultIncludes: conversation.request_body?.result_includes,
        topK: conversation.request_body?.top_k,
        isKeywordEnabled: conversation.request_body?.is_keyword_enabled,
        name: conversation.request_body?.name,
        intro: conversation.request_body?.intro,
        system: conversation.request_body?.system,
        model: conversation.request_body?.model,
        temperature: conversation.request_body?.temperature,
        topP: conversation.request_body?.top_p,
        frequencyPenalty: conversation.request_body?.frequency_penalty,
        presencePenalty: conversation.request_body?.presence_penalty,
        stop: conversation.request_body?.stop,
        searchMaxToken: conversation.request_body?.search_max_token,
        completionToken: conversation.request_body?.completion_token,
        visionSettings: conversation.request_body?.vision_settings,
        chatHistoryLength: conversation.request_body?.chat_history_length,
        introToken: conversation.request_body?.intro_token,
        systemToken: conversation.request_body?.system_token,
        isPersonalizerOnly: conversation.request_body?.is_personalizer_only,
        isAutoEvaluation: conversation.request_body?.is_auto_evaluation,
      },
      feedback: conversation.feedback,
      requestUrl: conversation.request_url,
      responseStatus: conversation.response_status,
      response: conversation.response,
      sections: conversation.sections,
      references: conversation.references,
      tokens: conversation.tokens,
      usage: {
        completionTokens: conversation.usage?.completion_tokens,
        promptTokens: conversation.usage?.prompt_tokens,
        totalTokens: conversation.usage?.total_tokens,
        queryTokens: conversation.usage?.query_tokens,
      },
      conversationContext: Array.isArray(conversation.conversation_context)
        ? conversation.conversation_context
        : [],
      evaluation: conversation.evaluation,
      conversationId: conversation.conversation_id,
      gptProfileId: conversation.gpt_profile_id,
      createdAt: conversation.created_at,
    };
  }
}
