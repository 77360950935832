<template>
  <div>
    <v-row>
      <v-col cols="12" md="2" class="pl-12">
        <v-card class="pa-2" flat>
          <strong>{{ `${$vuetify.lang.t("$vuetify.training.images_train")}` }}</strong>
        </v-card>
      </v-col>

      <v-col cols="12" md="8"> </v-col>

      <v-col cols="12" md="2">
        <v-btn
          v-if="!this.inProgress"
          color="primary"
          @click="startTraining"
          :disabled="$authorize([$role.USER]) || this.mapper.detail.response.image_url == null">
          <v-icon>mdi-play</v-icon>
          {{ `${$vuetify.lang.t("$vuetify.tooltip.start")}` }}
        </v-btn>
        <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
          {{ `${$vuetify.lang.t("$vuetify.training.training")}` }}
          <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
    <v-container class="pl-12">
      <v-row>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="filterTrainImageType"
            :items="filterTrainImageOptions"
            :label="$vuetify.lang.t('$vuetify.training.train_options')"
            :disabled="$authorize([$role.USER])"
            item-text="name"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-form
        ref="imageTrainForm"
        v-if="filterTrainImageType === 'train_image_within_specified_range'"
      >
        <v-row cols="12" md="6">
          <v-col cols="2">
            <span>Train From</span>
          </v-col>
          <v-col cols="6" md="4">
            <v-menu
              ref="trainFromImageMenu"
              v-model="trainFromManue"
              :close-on-content-click="false"
              :return-value.sync="trainFromDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="startDateField"
                  v-model="trainFromDate"
                  placeholder="input start date..."
                  prepend-inner-icon="mdi-calendar"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="$authorize([$role.USER])"
                  :rules="
                                    [
                                      validationRules.required,
                                      validationRules.startDateLessThanEndDate
                                    ]
                                  "
                ></v-text-field>
              </template>
              <v-date-picker v-model="trainFromDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="trainFromManue = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.trainFromImageMenu.save(trainFromDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row cols="12" md="6" class="mb-0 pb-0">
          <v-col cols="2">
            <span>Train To</span>
          </v-col>
          <v-col cols="6" md="4">
            <v-menu
              ref="trainToImageMenu"
              v-model="dateMenuTrainTo"
              :close-on-content-click="false"
              :return-value.sync="trainToDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="endDateField"
                  v-model="trainToDate"
                  placeholder="input end date..."
                  prepend-inner-icon="mdi-calendar"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="$authorize([$role.USER])"
                  :rules="
                                    [
                                      validationRules.required,
                                      validationRules.endDateGreaterThanStartDate
                                    ]
                                  "></v-text-field>
              </template>
              <v-date-picker v-model="trainToDate" no-title scrollable type="date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateMenuTrainTo = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.trainToImageMenu.save(trainToDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="showWarningModal" max-width="400"  @click:outside="cancelTrainAll">
      <v-card>
        <v-card-title class="headline">
          <v-icon left color="warning">mdi-alert</v-icon>
          {{`${$vuetify.lang.t("$vuetify.common.warning_title_txt")}`}}
        </v-card-title>
        <v-card-text>
          {{`${$vuetify.lang.t("$vuetify.training.training_images_warning_txt")}`}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="cancelTrainAll">
            {{ `${$vuetify.lang.t("$vuetify.common.cancel_btn")}` }}
          </v-btn>
          <v-btn color="warning" text @click="confirmTrainAll">
            {{ `${$vuetify.lang.t("$vuetify.common.proceed")}` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="updateResult !== ''" class="pl-11">
      <v-col cols="auto">
        <v-icon v-if="updateStatus === 'success'" color="#C8E6C9">mdi-check-circle</v-icon>
        <v-icon v-else-if="updateStatus === 'failed'" color="error">mdi-alert-circle</v-icon>
      </v-col>
      <v-col>
        <p>
          <span :class="{ 'error--text': updateStatus === 'failed' }">
            {{ updateResult }}
          </span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import {
  IMAGES_TRAIN,
  GET_TASK_STATUS,
} from "@/store/_actiontypes";

import getEnv from "@/config/env";

export default {
  props: {
    mapper: {
      type: Object,
      default: () => ({})
    },
    taskHistoryData: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      filterTrainImageType: "train_image_within_specified_range",
      filterTrainImageOptions: [
        { name: this.$vuetify.lang.t("$vuetify.training.train_image_within_specified_range"), value: "train_image_within_specified_range" },
        { name: this.$vuetify.lang.t("$vuetify.training.train_all_image"), value: "train_all_image" },
      ],
      trainFromDate: '',
      trainToDate: '',
      trainFromManue: false,
      trainToManue: false,
      dateMenuTrainTo: false,
      showWarningModal: false,
      validationRules: {
        required: value => !!value || this.$vuetify.lang.t("$vuetify.training.date_field_required"),
        startDateLessThanEndDate: value => !this.trainToDate || new Date(value) < new Date(this.trainToDate) || this.$vuetify.lang.t("$vuetify.training.start_date_validation"),
        endDateGreaterThanStartDate: value => !this.trainFromDate || new Date(value) > new Date(this.trainFromDate) || this.$vuetify.lang.t("$vuetify.training.start_date_validation")
      },
      isTrainAll: true,
      selectedFile: null,
      polling: null,
      inProgress: false,
      updateResult: "",
      updateStatus: "",
      hasImageURL: true
    };
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
    }),
  },
  watch: {
    filterTrainImageType: {
      handler(newValue, oldValue) {
        if (newValue !== "train_image_within_specified_range") {
          this.trainFromDate = "";
          this.trainToDate = "";
        }
        if (newValue === "train_all_image") {
          this.showWarningModal = true;
        } else {
          this.isTrainAll = false;
        }            },
      immediate: true,
    },
    trainFromDate() {
      this.$refs.endDateField.validate();
    },
    trainToDate() {
      this.$refs.startDateField.validate();
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions("project", [
      IMAGES_TRAIN,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    async startPolling(task_id) {
      this.polling = setInterval(() => {
        this.getTaskStatus(task_id);
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    stopPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    async getTaskStatus(task_id) {
      let project_id = this.selectedProject.id;
      this.GET_TASK_STATUS({
        project_id: project_id,
        task_id: task_id
      }).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.detail.response.status !== "pending") {
              this.stopPolling();
              this.inProgress = false;
              this.isLoading = false;
            }
            this.updateResult = response.data.detail.response.message;
            this.updateStatus = response.data.detail.response.status;
          }
        },
        (error) => {
          console.error(error.response);
        }
      );
    },
    async startTraining() {
      if (this.$refs.imageTrainForm && !this.$refs.imageTrainForm.validate()) {
        return;
      }
      let project_id = this.selectedProject.id;
      const formatDate = date => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      };
      const today = new Date();
      const endDate = formatDate(today);
      const startDate = formatDate(new Date(this.selectedProject.created_at));
      const start_date = this.isTrainAll ? startDate : this.trainFromDate;
      const end_date = this.isTrainAll ? endDate : this.trainToDate;
      this.inProgress = true;
      this.IMAGES_TRAIN({
        project_id: project_id,
        start_date: start_date,
        end_date: end_date,
      }).then(
        async (response) => {
          if (response.status === 202) {
            this.startPolling(response.data.detail.response);
          } else {
            this.inProgress = false;
            console.error(response);
          }
        },
        async (error) => {
          this.inProgress = false;
          console.error(error.response);
        }
      );
    },
    cancelTrainAll() {
      this.showWarningModal = false;
      this.filterTrainImageType = "train_image_within_specified_range";
    },
    confirmTrainAll() {
      this.showWarningModal = false;
      this.isTrainAll = true;
    },
  },
};
</script>

<style scoped>
.secondary-disabled-button {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
