<template>
  <v-container>
    <Loader v-if="isLoading" />
    <div v-else>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="coversation_detail_header">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <strong>
                    {{
                      this.$vuetify.lang.t(
                        "$vuetify.gpt_history.gpt_history_conversation_id"
                      )
                    }}:
                  </strong>
                  <span class="response-details-text">
                    {{ conversation.conversationId }}
                  </span>
                  <v-btn
                    icon
                    small
                    @click="
                      copyToClipboard(
                        conversation.conversationId,
                        'Conversation ID copied to clipboard'
                      )
                    "
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    class="green darken-1 white--text capitalize"
                    @click="sharePublicUri"
                  >
                    <v-icon left>mdi-share-variant</v-icon>
                    {{ this.$vuetify.lang.t("$vuetify.common.share_btn_txt") }}
                  </v-btn>
                  <div
                    v-if="credentialModal"
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      z-index: 1;
                      width: 20%;
                    "
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        {{
                          this.$vuetify.lang.t(
                            "$vuetify.gpt_history_details.share_credentials_header"
                          )
                        }}
                      </v-card-title>
                      <v-card-subtitle class="text-subtitle-2">
                        <v-icon
                          class="mr-1"
                          color="blue darken-1
                        "
                          >mdi-information</v-icon
                        >
                        {{
                          this.$vuetify.lang.t(
                            "$vuetify.gpt_history_details.share_credentials_info"
                          )
                        }}
                      </v-card-subtitle>

                      <v-card-text>
                        <v-form ref="credentialForm">
                          <v-text-field
                            v-model="project_key"
                            :label="
                              this.$vuetify.lang.t(
                                '$vuetify.project_credentials.project_key_label_txt'
                              )
                            "
                            outlined
                            :rules="[
                              v =>
                                !client_key && api_key
                                  ? !!v ||
                                    this.$vuetify.lang.t(
                                      '$vuetify.gpt_history_details.credential_form.project_key_required_txt'
                                    )
                                  : true,
                            ]"
                          ></v-text-field>
                          <v-text-field
                            v-model="api_key"
                            :label="
                              this.$vuetify.lang.t(
                                '$vuetify.project_credentials.api_key_label_txt'
                              )
                            "
                            outlined
                            :rules="[
                              v =>
                                !client_key && project_key
                                  ? !!v ||
                                    this.$vuetify.lang.t(
                                      '$vuetify.gpt_history_details.credential_form.api_key_required_txt'
                                    )
                                  : true,
                            ]"
                          ></v-text-field>
                          <v-container
                            class="d-flex align-center justify-center mb-2"
                          >
                            <v-divider class="flex-grow-1 mx-2"></v-divider>
                            <span class="mx-2">OR</span>
                            <v-divider class="flex-grow-1 mx-2"></v-divider>
                          </v-container>
                          <v-text-field
                            v-model="client_key"
                            :label="
                              this.$vuetify.lang.t(
                                '$vuetify.project_credentials.client_key_label_txt'
                              )
                            "
                            outlined
                            :rules="[
                              v =>
                                !project_key && !api_key
                                  ? !!v ||
                                    this.$vuetify.lang.t(
                                      '$vuetify.gpt_history_details.credential_form.client_key_required_txt'
                                    )
                                  : true,
                            ]"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="red darken-1"
                          text
                          @click="credentialModal = false"
                        >
                          {{
                            this.$vuetify.lang.t("$vuetify.common.close_btn")
                          }}
                        </v-btn>

                        <v-btn
                          color="green darken-1"
                          text
                          @click="manualLinkShare"
                        >
                          {{
                            this.$vuetify.lang.t(
                              "$vuetify.common.share_btn_txt"
                            )
                          }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_user_id"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody?.userId }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="">
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_query"
                  )
                }}:
              </strong>
              <span class="response-message">
                {{ conversation.requestBody?.query }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_response"
                  )
                }}:
              </strong>
              <v-card flat class="mt-1">
                <v-card-text
                  class="pa-1"
                  style="
                    max-height: 300px;
                    overflow-y: auto;
                    word-break: break-word;
                  "
                >
                  <VueMarkdown>
                    {{ normalizeTextContent(conversation.response) }}
                  </VueMarkdown>
                </v-card-text>
              </v-card>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="conversation.evaluation">
          <v-card flat>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>
                    {{
                      this.$vuetify.lang.t(
                        "$vuetify.gpt_history.gpt_history_is_evaluation"
                      )
                    }}:
                  </strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-card-subtitle>
              <v-chip color="primary" label small>
                <v-icon left small>mdi-star</v-icon>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_score"
                  )
                }}:
                {{ conversation.evaluation.response_score || 0 }}
              </v-chip>
            </v-card-subtitle>
          </v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.gpt_history.gpt_top_references"
                    )
                  }}:
                </strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-data-table
            v-if="
              conversation.evaluation.top_references &&
              conversation.evaluation.top_references.length
            "
            :headers="evaluationHeaders"
            :items="conversation.evaluation.top_references"
            :items-per-page="5"
            :mobile-breakpoint="0"
            show-expand
            item-key="score"
          >
            <template v-slot:item.score="{ item }">
              <v-chip :color="getScoreColor(item.score)" dark small>
                <span class="subtitle-2">{{ item.score }}</span>
              </v-chip>
            </template>
            <template v-slot:item.index="{ item }">
              <span>{{ formatIndexValues(item) }}</span>
            </template>
            <template v-slot:item.dataset_id="{ item }">
              <span>{{ item.dataset_id || "N/A" }}</span>
            </template>
            <template v-slot:item.source="{ item }">
              <span>{{ item.source || "N/A" }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <template v-if="item.source === 'conversation_context'">
                  <v-sheet
                    v-if="
                      conversation.conversationContext &&
                      conversation.conversationContext.length
                    "
                    max-height="300px"
                    class="overflow-y-auto"
                  >
                    <v-list dense class="py-0">
                      <v-list-item
                        v-for="(
                          context, index
                        ) in conversation.conversationContext"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-subtitle
                            class="text--primary text-caption text-sm-body-2"
                          >
                            <strong>User:</strong>
                            {{ context.user }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="text-wrap text-caption text-sm-body-2"
                          >
                            <strong>Assistant:</strong>
                            {{ context.assistant }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="text-caption">
                            <strong>Timestamp:</strong>
                            {{ formatDateTime(context.timestamp) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-sheet>
                </template>
                <template v-else-if="item.source === 'dataset'">
                  <VueMarkdown>
                    {{
                      getUniqueReferences(conversation.references)
                        ?.find(
                          ref =>
                            ref?.section_id ===
                            formatIndexValues(item).split(": ")[1]
                        )
                        ?.content.replace(
                          /(^|\s)(\b(?!http|https)\w+):/g,
                          "$1**$2**:"
                        ) || "N/A"
                    }}
                  </VueMarkdown>
                </template>
              </td>
            </template>
          </v-data-table>
        </template>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_date")
                }}:
              </strong>
              <span class="response-details-text">
                {{ formatDateTime(conversation.createdAt) }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_processing_time"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.processTime }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_model"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.model }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_chat_history_length"
                  )
                }}
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.chatHistoryLength }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_temperature"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.temperature }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Top P:</strong>
              <span class="response-details-text">
                {{ conversation.requestBody.topP }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Top K:</strong>
              <span class="response-details-text">
                {{ conversation.requestBody.topK }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_search_max_token"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.searchMaxToken }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_frequency_penalty"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.frequencyPenalty }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_presence_penalty"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.presencePenalty }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Stop:</strong>
              <span class="response-details-text">
                {{
                  conversation.requestBody.stop &&
                  conversation.requestBody.stop.join(", ")
                }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_result_includes"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{
                  conversation.requestBody.resultIncludes &&
                  conversation.requestBody.resultIncludes.join(", ")
                }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Session ID:</strong>
              <span class="response-details-text">
                {{ conversation.requestBody.sessionId }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>GPT Profile Id:</strong>
              <span class="response-details-text">
                {{ conversation.gptProfileId }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_profile_name"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.name }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Intro:</strong>
              <span class="response-details-text">
                {{ conversation.requestBody.intro }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_system"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.system }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_conversation_type"
                  )
                }}:
              </strong>
              <span class="response-details-text">
                {{ conversation.requestBody.conversationType }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="mt-0">
                <v-col class="py-0 pr-0" cols="12" md="auto">
                  <div class="font-weight-bold pt-2">
                    {{
                      this.$vuetify.lang.t(
                        "$vuetify.gpt_history.gpt_history_feedback_message"
                      )
                    }}:
                  </div>
                </v-col>
                <template v-if="!isEditing">
                  <v-col class="py-0 w-100">
                    <div
                      :class="[
                        'response-details-text pt-2',
                        isPrivate ? 'd-inline' : '',
                      ]"
                    >
                      {{
                        conversation.feedback &&
                        conversation.feedback.message !== undefined &&
                        conversation.feedback.message !== null &&
                        conversation.feedback.message !== ""
                          ? conversation.feedback.message
                          : updatedData.message !== undefined &&
                              updatedData.message !== null &&
                              updatedData.message !== ""
                            ? updatedData.message
                            : "N/A"
                      }}
                    </div>
                    <v-btn v-if="isPrivate" @click="toggleEditing" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                </template>
                <template v-else>
                  <v-col class="py-2 w-100">
                    <v-textarea
                      v-if="isEditing"
                      v-model="editedFeedback"
                      label=""
                      placeholder="Write your feedback here..."
                      auto-grow
                      :rows="dynamicRowCount"
                      max-rows="8"
                      outlined
                      dense
                    ></v-textarea>
                    <v-form ref="feedbackTextarea">
                      <div
                        class="d-flex flex-column flex-sm-row justify-space-between align-center"
                      >
                        <div>
                          <v-radio-group
                            v-if="isEditing"
                            v-model="editedFeedbackType"
                            row
                            class="response-details-text mt-0 flex-column flex-sm-row"
                            :rules="[
                              v =>
                                !!v ||
                                this.$vuetify.lang.t(
                                  '$vuetify.insights.feedback_type_required'
                                ),
                            ]"
                          >
                            <v-radio
                              :label="
                                this.$vuetify.lang.t(
                                  '$vuetify.insights.positive'
                                )
                              "
                              value="positive"
                            ></v-radio>
                            <v-radio
                              :label="
                                this.$vuetify.lang.t(
                                  '$vuetify.insights.negative'
                                )
                              "
                              value="negative"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                        <div>
                          <v-row align="center" v-if="isEditing">
                            <v-col>
                              <v-btn
                                outlined
                                @click="cancelEditing"
                                class="mr-2"
                                color="red"
                              >
                                {{
                                  this.$vuetify.lang.t("$vuetify.common.cancel")
                                }}
                              </v-btn>
                              <v-btn
                                @click.prevent="saveEditedFeedback"
                                color="primary"
                                outlined
                              >
                                {{
                                  this.$vuetify.lang.t("$vuetify.common.save")
                                }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-form>
                  </v-col>
                </template>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Sections:</strong>
              <span class="response-details-text">
                {{ conversation.sections && conversation.sections.join(", ") }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_context_preference"
                  )
                }}:
              </strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <p style="margin-left: 20px; font-weight: 500">Preferred:</p>
              <!-- <span style="margin-left: 40px">Dataset ID:</span>
              <span class="response-details-text">
                {{
                  conversation.requestBody.contextPreference &&
                  conversation.requestBody.contextPreference.preferred &&
                  conversation.requestBody.contextPreference.preferred.datasetId
                }}
              </span> -->
              <!-- <p></p> -->
              <!-- <span style="margin-left: 40px">Project ID:</span> -->
              <!-- <span class="response-details-text">
                {{
                  conversation.requestBody.contextPreference &&
                  conversation.requestBody.contextPreference.preferred &&
                  conversation.requestBody.contextPreference.preferred.projectId
                }}
              </span> -->
              <span class="response-details-text">
                <template
                  v-if="conversation.requestBody.contextPreference?.preferred"
                >
                  <div
                    class="pa-1"
                    v-for="(value, key) in conversation.requestBody
                      .contextPreference.preferred"
                    :key="key"
                  >
                    <span class="ml-5 font-weight-bold pe-2">{{ key }}</span
                    >: {{ value }}
                  </div>
                </template>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <p style="margin-left: 20px; font-weight: 500">Excludes:</p>
              <span class="response-details-text">
                <template
                  v-if="conversation.requestBody.contextPreference?.excludes"
                >
                  <div
                    class="pa-1"
                    v-for="(value, key) in conversation.requestBody
                      .contextPreference.excludes"
                    :key="key"
                  >
                    <span class="ml-5 font-weight-bold pe-2">{{ key }}</span
                    >: {{ value }}
                  </div>
                </template>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_conversation_metadata"
                  )
                }}:
              </strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            conversation.requestBody.conversationMetadata &&
            Object.keys(conversation.requestBody.conversationMetadata).length >
              0
          "
        >
          <v-list-item-content>
            <span class="response-details-text">
              <template>
                <div
                  class="pa-1"
                  v-for="(value, key) in conversation.requestBody
                    .conversationMetadata"
                  :key="key"
                >
                  <span class="ml-5 font-weight-bold pe-2">{{ key }}</span
                  >: {{ value }}
                </div>
              </template>
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>Usage:</strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_completion_tokens"
                  )
                }}:
              </span>
              <span>
                {{ conversation.usage && conversation.usage.completionTokens }}
              </span>
              <p></p>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_prompt_tokens"
                  )
                }}:
              </span>
              <span>
                {{ conversation.usage && conversation.usage.promptTokens }}
              </span>
              <p></p>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_query_tokens"
                  )
                }}:
              </span>
              <span>
                {{ conversation.usage && conversation.usage.queryTokens }}
              </span>
              <p></p>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_total_tokens"
                  )
                }}:
              </span>
              <span>
                {{ conversation.usage && conversation.usage.totalTokens }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_vision_settings"
                  )
                }}:
              </strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_image_context_enabled"
                  )
                }}:
              </span>
              <span>
                {{
                  conversation.requestBody.visionSettings &&
                  conversation.requestBody.visionSettings
                    .is_image_context_enabled
                }}
              </span>
              <p></p>
              <span style="margin-left: 20px; font-weight: 500">
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_resolution"
                  )
                }}:
              </span>
              <span>
                {{
                  conversation.requestBody.visionSettings &&
                  conversation.requestBody.visionSettings.resolution
                }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_history_references"
                  )
                }}:
              </strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="conversation.references">
          <v-data-table
            :headers="gptReferenceHeaders"
            :items="getUniqueReferences(conversation.references)"
            :items-per-page="5"
            show-expand
            item-key="section_id"
          >
            <template v-slot:[`item.content`]="{ item }">
              <span>
                {{ item.content && item.content.substring(0, 60) }}
                ...
              </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="word-break" :colspan="headers.length">
                <VueMarkdown>
                  {{
                    item?.content.replace(
                      /(^|\s)(\b(?!http|https)\w+):/g,
                      "$1**$2**:"
                    )
                  }}
                </VueMarkdown>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                v-if="isPrivate"
                small
                @click="redirectDocumentPage(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.gpt_history.gpt_conversation_context"
                  )
                }}:
              </strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-card
              flat
              class="ml-4"
              style="max-height: 400px; overflow-y: auto"
            >
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="(context, index) in conversation.conversationContext"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    <v-container fluid class="pa-0">
                      <v-row no-gutters align="center">
                        <v-col
                          cols="12"
                          sm="4"
                          md="2"
                          class="d-flex align-center mb-1 mb-sm-0"
                        >
                          <span class="text-subtitle-2 font-weight-medium">
                            Conversation-{{ index + 1 }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="8"
                          md="10"
                          class="d-flex flex-wrap align-center"
                        >
                          <v-chip x-small label outlined class="mr-2 mb-1">
                            <span class="text-subtitle-2">{{
                              context.user
                            }}</span>
                          </v-chip>
                          <span class="text-caption grey--text">
                            {{ formatDateTime(context.timestamp) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-text>
                      <strong class="primary--text">Assistant:</strong>
                      <VueMarkdown>
                        {{ normalizeTextContent(context.assistant) }}
                      </VueMarkdown>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import VueMarkdown from "vue-markdown";

import Loader from "@/components/common/loader/Loader";
import {
  ADD_ALERT,
  RECOMMENDER_UPDATE_FEEDBACK,
  RETRIEVE_GPT_CONVERSATION_DETAIL,
} from "@/store/_actiontypes";
import {
  decryptData,
  encryptData,
  getUniqueArrayOfObjects,
} from "@/helpers/helper";

export default {
  components: {
    Loader,
    VueMarkdown,
  },
  watch: {
    isPrivate(newValue) {
      if (!newValue) {
        this.gptReferenceHeaders = this.gptReferenceHeaders.filter(
          header => header.value !== "action"
        );
      } else {
        this.gptReferenceHeaders.push({
          text: "Action",
          value: "action",
        });
      }
    },
    project_key(newValue) {
      if (newValue) {
        this.client_key = "";
      }
      this.$refs.credentialForm?.validate();
    },
    api_key(newValue) {
      if (newValue) {
        this.client_key = "";
      }
      this.$refs.credentialForm?.validate();
    },
    client_key(newValue) {
      if (newValue) {
        this.project_key = "";
        this.api_key = "";
      }
      this.$refs.credentialForm?.validate();
    },
  },
  mounted() {
    this.getDataFromToken();
  },
  data() {
    return {
      credentialModal: false,
      project_key: "",
      api_key: "",
      client_key: "",
      decryptedData: null,
      isPrivate: false,
      editedFeedback: "",
      editedFeedbackType: "",
      isEditing: false,
      isLoading: true,
      dynamicRowCount: 1,
      conversation: {},
      updatedData: {
        conversation_id: null,
        message: null,
        type: null,
      },
      gptReferenceHeaders: [
        {
          text: "Content",
          align: "start",
          value: "content",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.dataset_id"),
          align: "start",
          value: "dataset_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.index"),
          align: "start",
          value: "index",
        },
        {
          text: "Similarity Score",
          align: "start",
          value: "similarity_score",
        },
        {
          text: "Section ID",
          align: "start",
          value: "section_id",
        },
      ],
      evaluationHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.insights.source"),
          value: "source",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.score"),
          value: "score",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.dataset_id"),
          value: "dataset_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.insights.index"),
          value: "index",
        },
      ],
    };
  },
  methods: {
    ...mapActions("gpt", [RETRIEVE_GPT_CONVERSATION_DETAIL]),
    ...mapActions("insight", [RECOMMENDER_UPDATE_FEEDBACK]),
    getDataFromToken() {
      const token = this.$route.query.token;
      const conversation_id = this.$route.params.conversation_id;
      const parts = conversation_id.split("-");
      const secretKey = `${parts[0]}#${parts[parts.length - 1]}`;
      this.decryptedData = decryptData(token, secretKey);
      this.isPrivate = this.decryptedData?.project_id ? true : false;
      this.getConversationDetails();
    },
    async getConversationDetails() {
      this.isLoading = true;
      this.decryptedData.conversation_id = this.$route.params.conversation_id;
      this.conversation = await this.RETRIEVE_GPT_CONVERSATION_DETAIL(
        this.decryptedData
      );
      this.isLoading = false;
    },
    // Normalizes response text content, extracting and formatting JSON if present.
    normalizeTextContent(response) {
      if (!this.isValidString(response)) {
        console.warn("Invalid response: expected a non-empty string");
        return "";
      }

      if (this.isJsonString(response)) {
        const jsonContent = this.extractJsonBlock(response);
        if (!jsonContent) {
          console.warn("No valid JSON block found in response");
          return this.normalizeBasicText(response); // Fallback
        }

        try {
          const parsed = JSON.parse(jsonContent);
          return this.formatJsonAsPlainText(parsed);
        } catch (e) {
          console.error("JSON parsing failed:", e);
        }
      }

      return this.normalizeBasicText(response);
    },
    //Normalize text formatting by removing unnecessary characters
    normalizeBasicText(text) {
      return this.cleanMarkdown(text)
        .replace(/"(\w+)":/g, "$1:")
        .replace(/['"]([^'"]+)['"]/g, "$1")
        .replace(/[[\]{}]/g, "")
        .replace(/\\n/g, "\n")
        .replace(/\r?\n/g, "\n")
        .replace(/,\s*$/gm, "")
        .replace(/^\s*,/gm, "")
        .trim();
    },
    // Checks if a string contains a valid JSON object wrapped in Markdown code blocks.
    isJsonString(str) {
      if (!this.isValidString(str)) return false;

      const jsonContent = this.extractJsonBlock(str);
      if (!jsonContent) return false;

      try {
        JSON.parse(jsonContent);
        return true;
      } catch {
        return false;
      }
    },
    //Formats a JSON object into a human-readable string with indentation and formatting.
    formatJsonAsPlainText(obj, level = 0) {
      const indent = "  ".repeat(level);
      return (
        Object.entries(obj)
          .map(([key, value]) => {
            const formattedKey = key.replace(/_/g, " ");
            if (Array.isArray(value)) {
              const items = value
                .map((item, index) =>
                  typeof item === "object" && item !== null
                    ? `${indent}**Product ${index + 1}:**\n${this.formatJsonAsPlainText(item, level + 1)}`
                    : `${indent}• ${item}`
                )
                .join("\n");
              return `${indent}**${formattedKey}:**\n${items}`;
            } else if (typeof value === "object" && value !== null) {
              return `${indent}**${formattedKey}:**\n${this.formatJsonAsPlainText(value, level + 1)}`;
            } else {
              return `${indent}**${formattedKey}:** ${value}`;
            }
          })
          .join("\n") + "\n"
      );
    },
    isValidString(value) {
      return value && typeof value === "string" && value.trim().length > 0;
    },
    extractJsonBlock(str) {
      const jsonStart = str.indexOf("```json");
      if (jsonStart === -1) return null;

      return this.cleanMarkdown(str.substring(jsonStart));
    },
    cleanMarkdown(content) {
      return content
        .replace(/```json\n?/, "")
        .replace(/```$/, "")
        .trim();
    },
    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);

      if (isNaN(date.getTime())) {
        console.warn("Invalid date time string:", dateTimeString);
        return "";
      }

      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");

      const dd = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const min = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    },
    getUniqueReferences(references) {
      return getUniqueArrayOfObjects(references, "section_id");
    },
    showAlertMessage(message, color) {
      this.$store.dispatch(
        `alert/${ADD_ALERT}`,
        {
          message: message,
          color: color,
        },
        { root: true }
      );
    },
    copyToClipboard(text, message) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showAlertMessage(message, "success");
        },
        err => {
          console.error("Could not copy text: ", err);
        }
      );
    },
    getScoreColor(score) {
      return score > 80
        ? "green"
        : score > 60
          ? "light-green"
          : score > 40
            ? "yellow"
            : score > 20
              ? "orange"
              : "red";
    },
    formatIndexValues(item) {
      const excludedFields = ["source", "score", "dataset_id"];
      const indexFields = Object.keys(item).filter(
        key => !excludedFields.includes(key)
      );
      const values = indexFields
        .map(field => {
          const value = item[field];
          return `${field}: ${value}`;
        })
        .filter(Boolean);
      return values.length ? values.join(", ") : "N/A";
    },
    saveEditedFeedback() {
      if (!this.$refs.feedbackTextarea.validate()) {
        return;
      }
      this.updateFeedback();
    },
    updateFeedback() {
      if (this.isPublic) {
        this.showAlertMessage(
          "You do not have permission to edit this feedback",
          "error"
        );
        return;
      }
      this.updateFeedbackFunction(this.decryptedData.project_id);
    },
    updateFeedbackFunction(project_id) {
      const updateFeedback = {
        conversation_id: this.conversation.conversationId,
        type: this.editedFeedbackType,
      };
      if (
        this.editedFeedback !== undefined &&
        this.editedFeedback.trim() !== ""
      ) {
        updateFeedback.message = this.editedFeedback;
      }
      this.RECOMMENDER_UPDATE_FEEDBACK({
        project_id: project_id,
        feedback: updateFeedback,
      }).then(
        _response => {
          this.cancelEditing();
          const updatedData = {
            conversation_id: updateFeedback.conversation_id,
            message: updateFeedback.message,
            type: updateFeedback.type,
          };
          this.conversation.feedback = {
            message: updatedData.message,
            type: updatedData.type,
          };
          this.showAlertMessage("Feedback updated successfully", "success");
          this.isEditing = false;
        },
        error => {
          console.error("Error updating insights:", error);
        }
      );
    },
    toggleEditing() {
      if (this.isPublic) {
        this.showAlertMessage(
          "You do not have permission to edit this feedback",
          "error"
        );
        return;
      }
      this.isEditing = !this.isEditing;
      if (this.isEditing && this.conversation.feedback) {
        if (this.conversation.feedback.message) {
          this.editedFeedback = this.conversation.feedback.message;
        } else {
          this.editedFeedback = "";
        }
        if (this.conversation.feedback.type) {
          this.editedFeedbackType = this.conversation.feedback.type;
        } else {
          this.editedFeedbackType = "";
        }
      } else {
        this.editedFeedback = "";
        this.editedFeedbackType = "";
      }
    },
    cancelEditing() {
      this.isEditing = false;
      this.editedFeedback = "";
      this.editedFeedbackType = "";
      this.showFeedbackTypeError = false;
      this.updateMessage = "";
    },
    redirectDocumentPage(item) {
      if (this.isPublic) {
        this.showAlertMessage(
          "You do not have permission to view this document",
          "error"
        );
        return;
      }
      if (!item.dataset_id) {
        this.showAlertMessage("Dataset ID is not available", "error");
        return;
      }
      this.$router.push(
        "/projects/" +
          this.decryptedData.project_id +
          "/datasets/" +
          item.dataset_id +
          "/documents?section_id=" +
          item.section_id
      );
    },
    sharePublicUri() {
      let publicUri = "";
      if (
        (this.decryptedData?.api_key && this.decryptedData?.project_key) ||
        this.decryptedData?.client_key
      ) {
        publicUri = `${window.location.href}`;
        this.copyToClipboard(publicUri, "Public URI copied to clipboard");
      } else {
        this.credentialModal = true;
      }
    },
    manualLinkShare() {
      if (!this.$refs.credentialForm.validate()) return;

      const data = this.client_key
        ? { client_key: this.client_key }
        : { project_key: this.project_key, api_key: this.api_key };

      const conversation_id = this.$route.params.conversation_id;
      const parts = conversation_id.split("-");
      const secretKey = `${parts[0]}#${parts[parts.length - 1]}`;
      const urlSafeEncryptedData = encryptData(data, secretKey);
      const baseUri = window.location.href.split("token=")[0];
      const uri = `${baseUri}token=${urlSafeEncryptedData}`;

      this.copyToClipboard(uri, "Public URI copied to clipboard");
      this.credentialModal = false;
    },
  },
};
</script>

<style scoped>
.response-message {
  white-space: pre-line;
}

.response-details-text {
  color: gray;
  white-space: pre-line;
}

.coversation_detail_header {
  font-size: 25px;
}

.input-field {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.textarea {
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
}

.text-subtitle-1 {
  font-weight: 600;
}

.word-break {
  word-break: break-all;
}
</style>
